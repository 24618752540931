import { useState } from 'react';

import { DEFAULT_CUSTOM_TEMPLATE } from '@lp-lib/shared-schema/src/agent/outline';

import { CopyButton } from '../common/CopyButton';
import { CopyIcon } from '../icons/CopyIcon';
import { GreenCheckIcon } from '../icons/GreenCheckIcon';
import { SettingIcon } from '../icons/SettingIcon';
import { XIcon } from '../icons/XIcon';
import { BlockMusicSelect } from './Editor/Shared/BlockMusicSelect';

export type OutlineConfig = {
  customTemplate: string;
  songId: string | null;
};

export function TrainingOutlineConfig(props: {
  value: OutlineConfig;
  onChange: (value: OutlineConfig) => void;
}) {
  const [controlledValue, setControlledValue] = useState(props.value);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <div className='absolute left-2.5 bottom-2.5 flex items-center gap-2'>
        {isOpen ? (
          <>
            <button
              type='button'
              className='btn'
              onClick={() => {
                setIsOpen(false);
                props.onChange(controlledValue);
              }}
            >
              <GreenCheckIcon className='w-5 h-5 fill-current' />
            </button>
            <button
              type='button'
              className='btn'
              onClick={() => {
                setIsOpen(false);
                setControlledValue(props.value);
              }}
            >
              <XIcon className='w-5 h-5 fill-red-002' />
            </button>
          </>
        ) : (
          <button type='button' className='btn' onClick={() => setIsOpen(true)}>
            <SettingIcon className='w-5 h-5 fill-secondary' />
          </button>
        )}
      </div>
      {isOpen && (
        <div
          className='w-[90%] h-100 absolute left-1 bottom-9 bg-black 
          border border-secondary rounded-lg text-white flex flex-col gap-2 shadow-lg'
        >
          <section className='flex items-center justify-between px-2 py-1 border-b border-secondary'>
            <label className='text-xs flex-shrink-0'>Background Music</label>
            <div className='w-1/2'>
              <BlockMusicSelect
                value={controlledValue.songId}
                onChange={(value) =>
                  setControlledValue((prev) => ({
                    ...prev,
                    songId: value?.songId ?? null,
                  }))
                }
              />
            </div>
          </section>
          <section className='px-2 py-1 flex flex-col gap-1 flex-grow'>
            <div className='flex items-center gap-2'>
              <label className='text-xs'>Custom Template</label>
              <CopyButton copiedText={DEFAULT_CUSTOM_TEMPLATE}>
                <CopyIcon />
              </CopyButton>
            </div>
            <textarea
              className='flex-1 field mb-0 p-2 bg-transparent border border-secondary rounded-lg outline-none resize-none'
              autoFocus
              value={controlledValue.customTemplate}
              placeholder={DEFAULT_CUSTOM_TEMPLATE}
              onChange={(e) =>
                setControlledValue((prev) => ({
                  ...prev,
                  customTemplate: e.target.value,
                }))
              }
            />
          </section>
        </div>
      )}
    </div>
  );
}
