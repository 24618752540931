import { Link, type MetaFunction, useNavigate } from '@remix-run/react';
import { useEffect, useRef, useState } from 'react';
import Marquee from 'react-fast-marquee';
import { useInView } from 'react-intersection-observer';

import { HelpWidget } from '../../../app/components/HelpWidget/HelpWidget';
import { useLiveAsyncCall } from '../../hooks/useAsyncCall';
import {
  type FeatureQueryParamArrays,
  getFeatureQueryParamArray,
} from '../../hooks/useFeatureQueryParam';
import { useInstance } from '../../hooks/useInstance';
import { useLiveCallback } from '../../hooks/useLiveCallback';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import { getStaticAssetPath } from '../../utils/assets';
import { makeMarketLink } from '../../utils/makeLinks';
import { UGCFileManagerProvider } from '../Game/UGC';
import { useUGCFileManager } from '../Game/UGC/CustomGameFileManager';
import {
  type ActionProps,
  type CustomEditorPrimaryAction,
  CustomGamePackPromptEditor,
} from '../Game/UGC/CustomGamePackPromptEditor';
import { MultipleChoiceBlockIcon } from '../icons/Block';
import { InstructionBlockIcon } from '../icons/Block/InstructionBlockIcon';
import { JeopardyBlockIcon } from '../icons/Block/JeopardyBlockIcon';
import { DocumentIcon } from '../icons/DocumentIcon';
import { GameIcon } from '../icons/GameIcon';
import { ImageIcon } from '../icons/ImageIcon';
import { LockIcon } from '../icons/LockIcon';
import { ScoreboardIcon } from '../icons/ScoreboardIcon';
import { InstgramIcon, LinkedInIcon } from '../icons/SocialIcon';
import { StarIcon } from '../icons/StarIcon';
import { VolumeIcon } from '../icons/VolumeIcon';
import { LearningLandingFAQs } from './Landing/Components/LearningLandingFaqs';
import { LearningLandingHeader } from './Landing/Components/LearningLandingHeader';
import { TestimonialCard } from './Landing/Components/TestimonialCard';
import {
  type OutlineConfig,
  TrainingOutlineConfig,
} from './TrainingOutlineConfig';
import { CaseStudyHeader } from './TrainingStarterStylish';
import { openTrainingTypeform } from './TrainingTypeform';
import { useCreateEmptyGamePack, useCreateGuestUser } from './utils';

const EMPLOYEE_ONBOARDING_URL = getStaticAssetPath(
  'images/employee-onboarding-laptop-phone.png'
);
const MULTIPLE_CHOICE_GIF_URL = getStaticAssetPath(
  'images/training-gameified.gif'
);
const JEOPARKY_GIF_URL = getStaticAssetPath('images/jeoparky.gif');
const HOW_SCORM_WORKS_URL = getStaticAssetPath('images/how-scorm-works.png');
const READY_TO_DIVE_IN_VID_URL = getStaticAssetPath(
  'videos/ready-to-dive-in.mp4'
);

const BANNER_VIDEO_RESPONSIVE_STYLES = {
  mobile: {
    url: getStaticAssetPath('videos/training-banner-mobile-v3.mp4'),
    poster: getStaticAssetPath('images/training-banner-mobile-v3.png'),
    aspectRatio: '1/1',
    maxHeight: undefined,
    objectFit: 'contain' as const,
  },
  desktop: {
    url: getStaticAssetPath('videos/training-banner-desktop-v3.mp4'),
    poster: getStaticAssetPath('images/training-banner-desktop-v3.png'),
    aspectRatio: undefined,
    maxHeight: '500px',
    objectFit: 'cover' as const,
  },
};

interface FadeInSectionProps {
  children: React.ReactNode;
  delay?: number;
  className?: string;
}

function FadeInSection({
  children,
  delay = 0,
  className = '',
}: FadeInSectionProps) {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <div
      ref={ref}
      className={`w-full transition-all duration-700 ease-in-out ${className}`}
      style={{
        opacity: inView ? 1 : 0,
        transform: inView ? 'translateY(0)' : 'translateY(20px)',
        transitionDelay: `${delay}ms`,
        willChange: 'opacity, transform',
      }}
    >
      {children}
    </div>
  );
}

export const meta: MetaFunction = () => {
  return [
    { title: 'Trainings | Luna Park' },
    {
      tagName: 'link',
      rel: 'preload',
      href: BANNER_VIDEO_RESPONSIVE_STYLES.desktop.poster,
      as: 'image',
    },
    {
      tagName: 'link',
      rel: 'preload',
      href: BANNER_VIDEO_RESPONSIVE_STYLES.mobile.poster,
      as: 'image',
    },
    {
      tagName: 'link',
      rel: 'preload',
      href: BANNER_VIDEO_RESPONSIVE_STYLES.desktop.url,
      as: 'fetch',
    },
    {
      tagName: 'link',
      rel: 'preload',
      href: BANNER_VIDEO_RESPONSIVE_STYLES.mobile.url,
      as: 'fetch',
    },
  ];
};

export function Banner(props: {
  tagline: 'instantly-create' | 'engaging-games' | 'ai-taught-courses';
  dimWidth: number;
  videoCtrl: 'enabled' | 'disabled' | 'disabled-with-height';
  widthClasses?: string;
}) {
  const { dimWidth, videoCtrl } = props;
  const bannerVideoStyles =
    dimWidth <= 640
      ? BANNER_VIDEO_RESPONSIVE_STYLES.mobile
      : BANNER_VIDEO_RESPONSIVE_STYLES.desktop;

  const taglineUrl =
    props.tagline === 'instantly-create'
      ? 'images/training-tagline-2.png'
      : props.tagline === 'ai-taught-courses'
      ? 'images/training-tagline-3.png'
      : 'images/training-tagline.png';

  return (
    <div className='w-full relative'>
      {videoCtrl === 'enabled' ? (
        <div className='w-full relative pointer-events-none'>
          <video
            className='w-full inset-0'
            src={bannerVideoStyles.url}
            poster={bannerVideoStyles.poster}
            muted
            autoPlay
            loop
            style={{
              aspectRatio: bannerVideoStyles.aspectRatio,
              maxHeight: bannerVideoStyles.maxHeight,
              objectFit: bannerVideoStyles.objectFit,
            }}
            preload='auto'
          />
          <div className='bg-black bg-opacity-40 absolute inset-0' />
        </div>
      ) : videoCtrl === 'disabled-with-height' ? (
        <div className='w-full h-30' />
      ) : null}
      <img
        className={`max-w-2xl absolute left-1/2 transform -translate-x-1/2 -translate-y-3/4 ${
          props.widthClasses ?? 'w-4/5 3xl:w-1/3'
        }`}
        src={getStaticAssetPath(taglineUrl)}
        alt='tagline'
      />
      <div className='w-full text-center text-base font-bold text-icon-gray mt-10 px-7.5'>
        3x better knowledge retention through personalized learning experiences
      </div>
    </div>
  );
}

type CustomButtonProps = ActionProps & {
  isLandingPage?: boolean;
};

function CustomActionButton(props: CustomButtonProps) {
  const { disabled, disabledReason, submit, abort, isLandingPage } = props;
  const submitting = disabledReason === 'submitting';

  const buttonClass = isLandingPage
    ? 'py-1.5 px-4 flex items-center justify-center bg-luna-primary text-black rounded font-normal text-sms sm:font-bold hover:opacity-90 transition-opacity'
    : 'py-1.5 px-4 flex items-center justify-center btn-delete font-normal text-sms sm:font-bold';

  return (
    <button
      type='button'
      className={buttonClass}
      disabled={disabledReason === 'emptyPrompt' ? false : disabled}
      title={
        disabledReason === 'filesNotReady' ? 'Wait for files to be ready' : ''
      }
      onClick={submitting ? abort : submit}
    >
      {submitting ? 'Stop' : 'Create ✨'}
    </button>
  );
}

type StarterOrigin = 'landing' | 'admin';

function Starter(props: {
  customAction?: CustomEditorPrimaryAction;
  alternateButton?: boolean;
  bottomLabel?: string;
  starterOrigin: StarterOrigin;
  agentic?: FeatureQueryParamArrays['agentic'][number];
}) {
  const fileman = useUGCFileManager();
  const createGamePack = useCreateEmptyGamePack(null);
  const createUser = useCreateGuestUser();
  const navigate = useNavigate();
  const [outlineConfig, setOutlineConfig] = useState<OutlineConfig>({
    customTemplate: '',
    songId: null,
  });
  const agenticEnabled =
    props.agentic === 'enabled' || props.agentic === 'code';

  const initFileman = useLiveCallback(async () => {
    if (fileman.inited) return;
    const user = await createUser();
    if (!user) return;
    const pack = await createGamePack();
    if (!pack) return;
    fileman.init(pack.id, []);
  });

  // We don't support files/knowledge base for agentic approach yet.
  useEffect(() => {
    if (agenticEnabled) {
      fileman.disable();
    } else {
      fileman.enable();
    }
  }, [fileman, agenticEnabled]);

  useEffect(() => {
    fileman.uppy.addPreProcessor(initFileman);
    return () => fileman.uppy.removePreProcessor(initFileman);
  }, [fileman.uppy, initFileman]);

  const {
    call: submit,
    state: { state, error },
  } = useLiveAsyncCall(async (prompt: string) => {
    if (prompt.trim().length === 0) {
      fileman.emitMessage('Please type in a topic.', 'error');
      return false;
    }

    const params = new URLSearchParams();

    if (props.starterOrigin !== 'landing' && !agenticEnabled) {
      const formResponse = await openTrainingTypeform();
      if (formResponse) {
        params.set('form-id', formResponse.formId);
        params.set('response-id', formResponse.responseId);
      }
    }

    const user = await createUser();
    if (!user) return false;
    const pack = await createGamePack();
    if (!pack) return false;
    params.set('prompt', prompt);
    params.set('id', pack.id);
    params.set('starter-origin', props.starterOrigin);
    if (agenticEnabled) {
      params.set('agentic', props.agentic ?? 'enabled');
      if (outlineConfig.customTemplate.trim().length > 0) {
        params.set('custom-template', outlineConfig.customTemplate);
      }
      if (outlineConfig.songId) {
        params.set('song-id', outlineConfig.songId);
      }
    }
    navigate(`/learning/assistant?${params.toString()}`);
    return true;
  });

  return (
    <CustomGamePackPromptEditor
      enabled
      onSubmit={submit}
      onAbort={() => void 0}
      isSubmitting={state.isRunning}
      wrapperClassName='mt-auto py-4'
      active
      autoFocus
      error={error}
      placeholder='What training course are you building today?'
      disableDeactivate
      width='w-full md:w-160'
      borderVariant={
        props.customAction
          ? props.starterOrigin === 'landing'
            ? 'luna-gradient'
            : 'gradient'
          : 'secondary'
      }
      bottomLabel={
        props.bottomLabel ?? 'Create for free. No credit card required'
      }
      alternateButton={props.alternateButton}
      accessory={
        agenticEnabled ? (
          <TrainingOutlineConfig
            value={outlineConfig}
            onChange={setOutlineConfig}
          />
        ) : null
      }
    >
      {props.customAction}
    </CustomGamePackPromptEditor>
  );
}

function LogoRow() {
  const logos = useInstance(() => [
    'Amazon',
    'Salesforce',
    'Spotify',
    'IBM',
    'Tiktok',
    'HubSpot',
    'Stripe',
    'Mastercard',
    'Warby',
    'DoorDash',
    'Lyra',
    'Confluent',
    'DuckDuckGo',
    'CodeCademy',
    'Snapdocs',
    'Instacart',
  ]);

  return (
    <FadeInSection>
      <section className='w-full bg-black py-8'>
        <div className='flex items-center justify-center gap-1 text-sms text-icon-gray mb-4'>
          <LockIcon className='w-3.5 h-3.5 fill-current text-icon-gray' />
          <p>Loved by privacy-focused companies like</p>
        </div>

        <Marquee gradient={false} speed={50} className='h-9' autoFill>
          <div className='flex items-center gap-10 object-contain overflow-visible'>
            {logos.map((logo) => (
              <img
                key={logo}
                src={getStaticAssetPath(`images/training/logos/${logo}.png`)}
                alt={logo}
                className='max-w-20 max-h-9 logo-image'
              />
            ))}
          </div>
        </Marquee>
      </section>
    </FadeInSection>
  );
}
function UseCasesRow() {
  const dim = useWindowDimensions(20);
  const useCases = [
    { emoji: '📋', text: 'Employee Onboarding' },
    { emoji: '👆', text: 'Team Upskilling' },
    { emoji: '👆', text: 'Customer Service Training' },
    { emoji: '📈', text: 'Sales Training' },
    { emoji: '🎯', text: 'Leadership Development' },
    { emoji: '⚙️', text: 'Compliance Training' },
    { emoji: '🚀', text: 'Career Development' },
    { emoji: '💪', text: 'Product Training' },
  ];

  // Fade logic
  const fadeStyle =
    dim.width >= 640
      ? {
          WebkitMaskImage:
            'linear-gradient(to right, transparent, black 100px, black calc(100% - 100px), transparent)',
          maskImage:
            'linear-gradient(to right, transparent, black 100px, black calc(100% - 100px), transparent)',
        }
      : {
          WebkitMaskImage:
            'linear-gradient(to bottom, black 30%, black calc(100% - 50px), transparent 100%)',
          maskImage:
            'linear-gradient(to bottom, black 30%, black calc(100% - 50px), transparent 100%)',
        };

  return (
    <FadeInSection>
      <section className='w-full py-8'>
        <div className='max-w-5xl mx-auto px-4 sm:px-6 md:px-8 flex flex-col gap-5 text-white items-center'>
          <h2 className='text-xl hidden sm:block'>Use cases</h2>
          <div className='relative w-full'>
            <div className='overflow-x-auto pb-4 sm:pb-0' style={fadeStyle}>
              <div className='flex flex-wrap gap-2.5 justify-center'>
                {useCases.map(({ emoji, text }, idx) => (
                  <div
                    key={idx}
                    className='inline-flex items-center bg-dark-gray border border-[#868686]
                      rounded-full px-2 py-1 sm:px-5 sm:py-4 text-sms md:text-base
                      min-w-[100px] flex-none
                      whitespace-nowrap'
                  >
                    <span className='mr-2'>{emoji}</span>
                    <span className='italic font-bold'>{text}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </FadeInSection>
  );
}

function InstantlyCreate() {
  return (
    <FadeInSection>
      <section className='w-full py-12'>
        <div className='max-w-4xl mx-auto px-4 sm:px-6 md:px-8 flex flex-col items-center text-center gap-5'>
          <h2 className='text-2xl sm:text-4xl font-noto-sans font-bold text-white'>
            <span className='text-luna-primary'>Instantly</span> Create
            Interactive Learning Experiences.
          </h2>
          <p className='text-lg text-icon-gray font-bold'>
            With our Instant Creator, you can transform your ideas into engaging
            content tailored to your audience. Choose from three dynamic formats
            to bring your vision to life.
          </p>
        </div>
      </section>
    </FadeInSection>
  );
}

function EngagingCourses() {
  return (
    <FadeInSection delay={100}>
      <section className='w-full py-12'>
        <div className='max-w-5xl mx-auto px-4 sm:px-6 md:px-8 flex flex-col sm:flex-row items-center gap-20'>
          <div className='flex-1'>
            <img
              src={EMPLOYEE_ONBOARDING_URL}
              alt='Employee Onboarding'
              className='w-full h-full object-cover rounded-lg'
            />
          </div>
          <div className='flex-1 flex flex-col space-y-4 text-icon-gray'>
            <h2 className='text-white text-2xl sm:text-3xl lg:text-4xl font-noto-sans font-bold'>
              Engaging Courses: Your Content, Elevated
            </h2>
            <p>
              Deliver interactive training experiences designed to captivate and
              educate. Our course format is perfect for structured learning,
              offering:
            </p>

            <div className='flex flex-col gap-2 pl-1.5'>
              <div className='flex items-start gap-2.5'>
                <DocumentIcon className='flex-shrink-0 w-5 h-5 fill-current text-luna-primary mt-0.5' />
                <span className='text-white font-bold'>
                  Slides&nbsp;
                  <span className='text-icon-gray font-normal'>
                    to present key ideas.
                  </span>
                </span>
              </div>
              <div className='flex items-start gap-2.5'>
                <ImageIcon className='flex-shrink-0 w-5 h-5 fill-current text-luna-primary mt-0.5' />
                <span className='text-white font-bold'>
                  Images&nbsp;
                  <span className='text-icon-gray font-normal'>
                    to make content visually engaging.
                  </span>
                </span>
              </div>
              <div className='flex items-start gap-2.5'>
                <VolumeIcon className='flex-shrink-0 w-5 h-5 fill-current text-luna-primary mt-0.5' />
                <span className='text-white font-bold'>
                  Voiceovers&nbsp;
                  <span className='text-icon-gray font-normal'>
                    for added clarity and emphasis.
                  </span>
                </span>
              </div>
              <div className='flex items-start gap-2.5'>
                <MultipleChoiceBlockIcon className='flex-shrink-0 w-5 h-5 fill-current text-luna-primary mt-0.5' />
                <span className='text-white font-bold'>
                  Basic quizzes&nbsp;
                  <span className='text-icon-gray font-normal'>
                    to reinforce and test knowledge.
                  </span>
                </span>
              </div>
            </div>

            <p>
              Whether you're onboarding employees or upskilling your team, our
              Courses provide a familiar yet powerful way to learn.
            </p>
          </div>
        </div>
      </section>
    </FadeInSection>
  );
}

function Quizzes() {
  return (
    <FadeInSection delay={200}>
      <section className='w-full py-12'>
        <div className='max-w-4xl mx-auto px-4 sm:px-6 md:px-8 flex flex-col sm:flex-row items-center gap-20'>
          <div className='flex-1 flex flex-col space-y-4 text-icon-gray order-2 sm:order-1'>
            <h2 className='text-white text-2xl sm:text-3xl lg:text-4xl font-noto-sans font-bold'>
              Quizzes: Test Knowledge, Spark Fun
            </h2>
            <p>
              Make learning competitive and fun with interactive quizzes
              inspired by tools like Kahoot. Features include:
            </p>

            <div className='flex flex-col gap-2 pl-1.5'>
              <div className='flex items-start'>
                <MultipleChoiceBlockIcon className='flex-shrink-0 w-5 h-5 fill-current text-luna-primary mt-0.5' />
                <span className='ml-2.5 text-white font-bold'>
                  Customizable question formats.
                </span>
              </div>
              <div className='flex items-start'>
                <ScoreboardIcon className='flex-shrink-0 w-5 h-5 fill-current text-luna-primary mt-0.5' />
                <span className='ml-2.5 text-white font-bold'>
                  Real-time scoring to keep participants engaged.
                </span>
              </div>
              <div className='flex items-start'>
                <InstructionBlockIcon className='flex-shrink-0 w-5 h-5 fill-current text-luna-primary mt-0.5' />
                <span className='ml-2.5 text-white font-bold'>
                  Perfect for knowledge checks, team-building activities
                </span>
              </div>
            </div>

            <p>Turn any topic into an exciting challenge!</p>
          </div>

          <div className='flex-1 order-1 sm:order-2  max-w-[220px]'>
            <img
              src={MULTIPLE_CHOICE_GIF_URL}
              alt='Quiz Example'
              className='w-full h-full object-cover rounded-lg'
            />
          </div>
        </div>
      </section>
    </FadeInSection>
  );
}

function GamesPlayLearnWin() {
  return (
    <FadeInSection delay={300}>
      <section className='w-full py-12'>
        <div className='max-w-4xl mx-auto px-4 sm:px-6 md:px-8 flex flex-col sm:flex-row items-center gap-20'>
          <div>
            <img
              src={JEOPARKY_GIF_URL}
              alt='Jeoparky Example'
              className='w-full h-full object-cover rounded-lg max-w-[220px]'
            />
          </div>
          <div className='flex-1 flex flex-col space-y-4 text-icon-gray'>
            <h2 className='text-white text-2xl sm:text-3xl lg:text-4xl font-noto-sans font-bold'>
              Games: Play, Learn, Win
            </h2>
            <p>
              Bring the thrill of game shows to your training sessions! Our Game
              format offers experiences inspired by:
            </p>

            {/* Icons restored */}
            <div className='flex flex-col gap-2 pl-1.5'>
              <div className='flex items-start'>
                <JeopardyBlockIcon className='flex-shrink-0 w-5 h-5 fill-current text-luna-primary mt-0.5' />
                <span className='ml-2.5 text-white font-bold'>
                  Jeopardy:{' '}
                  <span className='text-icon-gray font-normal'>
                    Test participants’ knowledge across categories.
                  </span>
                </span>
              </div>
              <div className='flex items-start'>
                <StarIcon className='flex-shrink-0 w-5 h-5 fill-current text-luna-primary mt-0.5' />
                <span className='ml-2.5 text-white font-bold'>
                  Who Wants to Be a Millionaire:{' '}
                  <span className='text-icon-gray font-normal'>
                    Add suspense with tiered rewards and lifelines.
                  </span>
                </span>
              </div>
              <div className='flex items-start'>
                <GameIcon className='flex-shrink-0 w-5 h-5 fill-current text-luna-primary mt-0.5' />
                <span className='ml-2.5 text-white font-bold'>
                  And More:{' '}
                  <span className='text-icon-gray font-normal'>
                    Create your own unique games with custom questions and
                    prizes.
                  </span>
                </span>
              </div>
            </div>

            <p>Learning doesn’t have to be boring—make it unforgettable.</p>
          </div>
        </div>
      </section>
    </FadeInSection>
  );
}

function ExportScormFiles() {
  return (
    <FadeInSection delay={400}>
      <section className='w-full pt-12 pb-18'>
        <div className='max-w-4xl mx-auto px-4 sm:px-6 md:px-8 flex flex-col gap-10'>
          <div className='flex flex-col items-center text-center gap-5'>
            <h2 className='text-2xl sm:text-4xl font-noto-sans font-bold text-white'>
              Integrate with your LMS
            </h2>
            <p className='text-lg text-icon-gray font-bold'>
              Take your custom content to the next level with seamless SCORM
              file exports, making it easy to integrate into your existing
              Learning Management System (LMS). Designed with L&D content
              creators in mind, our SCORM export feature ensures your training
              materials are versatile, scalable, and ready to deliver results.
            </p>
          </div>

          <div className='flex flex-col sm:flex-row items-center gap-20'>
            <div className='flex-1'>
              <img
                src={HOW_SCORM_WORKS_URL}
                alt='How SCORM Works'
                className='w-full h-full object-cover rounded-lg'
              />
            </div>
            <div className='flex-1 flex flex-col gap-4 text-icon-gray'>
              <h3 className='text-2xl sm:text-3xl lg:text-4xl font-noto-sans font-bold text-white'>
                How it Works
              </h3>
              <ol className='list-decimal pl-4 space-y-2'>
                <li>
                  Create your experience using our{' '}
                  <span className='font-bold text-white'>Instant Creator</span>.
                </li>
                <li>Personalize every element to suit your training goals.</li>
                <li>Export as a SCORM file in just a few clicks.</li>
                <li>
                  Upload the SCORM file to your LMS and start delivering
                  impactful training.
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>
    </FadeInSection>
  );
}

type BringInspirationProps = {
  onGetStartedClick?: () => void;
};

function BringInspiration({ onGetStartedClick }: BringInspirationProps) {
  return (
    <FadeInSection delay={500}>
      <section className='bg-[#F8F8F8] w-full pt-8'>
        <div className='max-w-5xl mx-auto px-4 sm:px-6 md:px-8 flex flex-col lg:flex-row items-center gap-8'>
          <div className='flex flex-col gap-4 py-8 lg:py-16 flex-1'>
            <div className='max-w-xl flex flex-col gap-2.5 lg:gap-4'>
              <h2 className='font-noto-sans font-bold text-2xl lg:text-4xl text-black'>
                Bring Your Inspiration to Life
              </h2>
              <p className='text-icon-gray text-base font-bold lg:text-lg'>
                No matter your goal, our Instant Creator empowers you to design
                content that resonates. Simply share your content inspiration,
                choose your format, and watch your ideas come to life in
                seconds.
              </p>
              <button
                className='w-40 h-10 flex items-center justify-center text-sms font-bold mt-4 bg-luna-primary text-black rounded-md hover:opacity-90 transition-opacity'
                onClick={onGetStartedClick}
                type='button'
              >
                Get Started
              </button>
            </div>
          </div>
          <video
            src={READY_TO_DIVE_IN_VID_URL}
            muted
            autoPlay
            loop
            style={{
              maxHeight: '460px',
              maxWidth: '100%',
              objectFit: 'cover',
            }}
            preload='auto'
          />
        </div>
      </section>
    </FadeInSection>
  );
}

function Testimonial() {
  return (
    <FadeInSection delay={600}>
      <section className='bg-black w-full relative px-4 py-24 flex items-center justify-center min-h-[500px]'>
        <div className='max-w-3xl px-0 sm:px-4 mx-auto flex items-center justify-center'>
          <TestimonialCard
            avatarUrl={getStaticAssetPath(
              'images/training/testimonials/avatars/white-knight.png'
            )}
            name='Justin Hansen'
            position='Director of Training and Development'
            company='White Knight Pest Control'
            companyLogoUrl={getStaticAssetPath(
              'images/training/testimonials/logos/white-knight.png'
            )}
            quote='Having AI roleplay with our techs will save us countless hours!'
          />
        </div>
      </section>
    </FadeInSection>
  );
}

function CaseStudy() {
  return (
    <FadeInSection delay={700}>
      <section className='bg-black w-full relative min-h-[300px] sm:min-h-0'>
        <div className='absolute inset-0 bg-gradient-to-t from-luna-primary opacity-30 to-transparent pointer-events-none' />
        <div className='max-w-5xl sm:h-140 lg:h-160 mx-auto px-4 sm:px-6 md:px-8 flex flex-col items-center justify-center lg:justify-start lg:items-start gap-4 py-8 lg:pt-16 lg:pb-28 relative z-10'>
          <div className='w-full sm:w-2/3 flex flex-col gap-2.5 lg:gap-4 text-left sm:text-left my-auto'>
            <CaseStudyHeader branding='luna' />
            <div className='font-noto-sans font-bold text-2xl lg:text-4xl text-white'>
              KPMG uses gamified learning to increase fees by{' '}
              <span className='text-luna-primary'>25%</span>
            </div>

            <div className='text-icon-gray text-base lg:text-xl font-bold'>
              Discover how KPMG leveraged a learning game to bridge knowledge
              gaps... increased employee engagement... and fueled remarkable
              revenue growth.
            </div>

            <div className='flex justify-center sm:justify-start'>
              <Link
                className='w-40 h-10 flex items-center justify-center text-sms font-bold mt-4 bg-luna-primary text-black rounded-md hover:opacity-90 transition-opacity'
                to='https://lunapark.com/blog/kpmg-globerunner-a-case-study-in-gamified-learning/'
              >
                Learn More
              </Link>
            </div>
          </div>

          <img
            className='w-72 absolute bottom-0 right-4 hidden lg:block'
            src={getStaticAssetPath('images/training-case-study-cover.png')}
            alt='case-study'
          />
        </div>
      </section>
    </FadeInSection>
  );
}

function Footer() {
  return (
    <footer className='w-full bg-black sm:bg-lp-gray-009 py-3 pb-4'>
      <div className='max-w-7xl mx-auto px-6'>
        <div className='flex flex-row items-start sm:items-center justify-between'>
          <div className='flex flex-col sm:flex-row items-start sm:items-center gap-4 sm:gap-8'>
            <div className='flex flex-col sm:flex-row gap-4 sm:gap-8 text-sms'>
              <Link
                to={makeMarketLink('/terms-of-use/')}
                className='text-white hover:text-gray-300'
                target='_blank'
                rel='noopener noreferrer'
              >
                Terms of Service
              </Link>
              <Link
                to={makeMarketLink('/privacy-policy/')}
                className='text-white hover:text-gray-300'
                target='_blank'
                rel='noopener noreferrer'
              >
                Privacy Policy
              </Link>
            </div>
          </div>
          <div className='flex flex-row items-start space-x-4 mt-3.5 sm:mt-0'>
            <Link
              to='https://www.instagram.com/golunapark'
              className='text-white hover:text-gray-300'
              target='_blank'
              rel='noopener noreferrer'
            >
              <InstgramIcon />
            </Link>
            <Link
              to='https://www.linkedin.com/company/golunapark'
              className='text-white hover:text-gray-300'
              target='_blank'
              rel='noopener noreferrer'
            >
              <LinkedInIcon />
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
}

type TrainingGenAIStarterProps = {
  userIsntLoggedIn: boolean;
};

export function AnonTrainingGenAIStarter(props: TrainingGenAIStarterProps) {
  const { userIsntLoggedIn } = props;
  const dim = useWindowDimensions(20);

  const scrollableRef = useRef<HTMLDivElement>(null);

  const scrollToTop = () => {
    if (scrollableRef.current) {
      scrollableRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const [openHelpWidget, setOpenHelpWidget] = useState(false);

  return (
    <div
      className='w-full h-full overflow-y-auto scrollbar font-noto-sans'
      ref={scrollableRef}
    >
      <LearningLandingHeader
        userIsntLoggedIn={userIsntLoggedIn}
        source='main'
        lpLogoRedirect='/learning/starter'
      />
      <main className='flex flex-col items-center'>
        <Banner
          dimWidth={dim.width}
          tagline='ai-taught-courses'
          videoCtrl='enabled'
        />
        <section className='w-full'>
          <div className='max-w-7xl mx-auto px-4 sm:px-6 md:px-8 py-8'>
            <UGCFileManagerProvider>
              <Starter
                customAction={(props) => (
                  <CustomActionButton {...props} isLandingPage={true} />
                )}
                alternateButton
                starterOrigin='landing'
              />
            </UGCFileManagerProvider>
          </div>
        </section>
        <LogoRow />
        <UseCasesRow />
        <InstantlyCreate />
        <EngagingCourses />
        <div className='w-full'>
          <Quizzes />
          <GamesPlayLearnWin />
          <ExportScormFiles />
        </div>
        <BringInspiration onGetStartedClick={scrollToTop} />
        <Testimonial />
        <CaseStudy />
        <LearningLandingFAQs />
      </main>
      <Footer />
      <HelpWidget open={openHelpWidget} setOpen={setOpenHelpWidget} />
    </div>
  );
}

export function TrainingGenAIStarter() {
  const dim = useWindowDimensions(20);
  const [agentic] = useState(getFeatureQueryParamArray('agentic'));
  return (
    <div className='w-full'>
      <main>
        <Banner
          dimWidth={dim.width}
          tagline='instantly-create'
          widthClasses='w-4/5 lg:w-2/5 md:w-3/5'
          videoCtrl='disabled-with-height'
        />
        <UGCFileManagerProvider>
          <div className='px-7.5 py-8'>
            <Starter
              alternateButton={false}
              bottomLabel='AI Editor can make mistakes. Please check your work.'
              starterOrigin='admin'
              agentic={agentic}
            />
          </div>
        </UGCFileManagerProvider>
      </main>
    </div>
  );
}
