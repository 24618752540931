import { LLMBlockType } from './block';

export type BlockDraft = {
  id: string;
  gist: string;
  tool?: 'WebSearch' | 'LLM' | 'None';
  toolInput?: string;
  toolOutput?: unknown;
  detail?: string;
  notes?: string;
  blockType?: LLMBlockType;
};

export type Module = {
  name: string;
  blockDrafts: BlockDraft[];
};

export const DEFAULT_CUSTOM_TEMPLATE = `[1st Module Name]
- Markdown Block - As an agenda for the course. This simply be a bullet pointed list of what’s to come in the course.
- Markdown Block - Introduction to overall topic
- Markdown Block - Content, add one image for the content
- Markdown Block - Content, add one image for the content
- Interactive Block - Quiz the user about the content that’s been covered so far
- Interactive Block - Quiz the user about the content that’s been covered so far
- Markdown Block - Content, add one image for the content
- Markdown Block - Content, add one image for the content
- Interactive Block - Quiz the user about the content that’s been covered so far
- Interactive Block - Quiz the user about the content that’s been covered so far
- Markdown Block - Conclusion. Conclude the topic and wrap things up`;
